<template>
  <footer v-if="!isPartner" class="mt-6 bg-red-dark text-white">
    <div
      class="container mx-auto grid gap-2 px-4 py-6 md:grid-cols-2 md:gap-x-4 lg:gap-x-6"
    >
      <div class="flex items-start gap-4">
        <img
          src="~/assets/image/logo-new-lite.png"
          width="80"
          height="auto"
          alt="Hungry Hub Logo"
        />
        <div>
          <h3 class="mb-2 text-base font-bold lg:text-lg">Download App</h3>
          <p class="text-xs lg:text-sm">{{ backendConfig.appTitle }}</p>
          <p class="text-xs lg:text-sm">{{ backendConfig.appDescription }}</p>
          <div class="mt-3 flex items-center gap-4">
            <a
              :href="IOS_STORE_LINK"
              target="_top"
              class="flex items-center text-sm lg:text-base"
            >
              <MdiApple class="mr-2 h-7 w-7" />
              App Store
            </a>
            <a
              :href="ANDROID_STORE_LINK"
              target="_top"
              class="flex items-center text-sm lg:text-base"
            >
              <MdiGooglePlay class="mr-2 h-7 w-7" />
              Play Store
            </a>
          </div>
        </div>
      </div>
      <div class="">
        <h3 class="mb-2 text-base font-bold lg:text-lg">{{ t("followUs") }}</h3>
        <div class="mb-3 flex items-center gap-2">
          <a
            href="https://www.facebook.com/hungryhub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdiFacebook class="h-8 w-8" />
          </a>

          <a
            href="https://twitter.com/hungryhub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdiTwitterCircle class="h-8 w-8" />
          </a>
          <a
            href="https://www.instagram.com/hungryhub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MdiInstagram class="h-8 w-8" />
          </a>
          <a
            href="https://lin.ee/3AZEbKQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiLineFill class="h-8 w-8" />
          </a>
        </div>
        <div
          class="mb-2 grid grid-cols-2 gap-2 text-xs sm:grid-cols-4 md:grid-cols-3 lg:grid-cols-4 lg:text-sm"
        >
          <a
            href="#"
            class="hover:underline"
            rel="noopener noreferrer"
            @click="openParentPath('/restaurants/top')"
          >
            {{ t("topRestaurant") }}
          </a>
          <a
            href="https://hungryhub.zendesk.com/hc/en-us/sections/360001180074"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("hungryPoint") }}
          </a>
          <a
            href="https://hungryhub.zendesk.com/hc/en-us/sections/360001198573-การจอง-How-to-book"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("howToBook") }}
          </a>
          <a
            href="https://hungryhub.zendesk.com/hc/en-us/sections/360001198453-ทั-วไป-General"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("faq") }}
          </a>
          <a
            href="https://blog.hungryhub.com/"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("blog") }}
          </a>
          <a
            href="https://blog.hungryhub.com/hungry-hub-team-2/"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("meetTheTeam") }}
          </a>
          <a
            href="https://hungryhub.zendesk.com/hc/en-us/articles/360009040074"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("privacy") }}
          </a>
          <a
            href="https://hungryhub.zendesk.com/hc/en-us/articles/360005673074"
            target="_blank"
            class="hover:underline"
            rel="noopener noreferrer"
          >
            {{ t("contactUs") }}
          </a>
        </div>
      </div>
      <p class="text-xs md:col-span-2 lg:text-sm">
        Copyright © {{ new Date().getFullYear() }} Hungry Hub by AppServation
        Co., Ltd. All right reserved.
      </p>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { IOS_STORE_LINK, ANDROID_STORE_LINK } from "~/constants";
import MdiGooglePlay from "~icons/mdi/google-play";
import MdiApple from "~icons/mdi/apple";
import MdiFacebook from "~icons/mdi/facebook";
import MdiTwitterCircle from "~icons/mdi/twitter-circle";
import MdiInstagram from "~icons/mdi/instagram";
import RiLineFill from "~icons/ri/line-fill";
import useConfigStore from "~/stores/config";
import { usePartner } from "~/composables/usePartner";

const { isPartner } = usePartner();
const configStore = useConfigStore();
const { backendConfig, callerDomain } = storeToRefs(configStore);

function openParentPath(path: string) {
  if (!callerDomain.value) return;
  window.parent.location.href = callerDomain.value + path;
}

const { t } = useI18n({
  messages: {
    en: {
      webTitle:
        "Thailand’s No.1 Buffet App, offers you an exclusive All You Can Eat concept at normal ala carte restaurants. ",
      endWebTitle: "Free Booking! Free Cancellation!",
      followUs: "Follow Us",
      bookARestaurant: "Book a Restaurant",
      newRestaurant: "New Restaurants",
      topRestaurant: "Top Restaurants",
      groupDinning: "Group Dining",
      blogReview: "Blogger Reviews",
      support: "Support",
      howToBook: "How to book",
      faq: "FAQs",
      askAQuestion: "Ask a Question",
      shareFeedback: "Share Feedback",
      aboutHungryhub: "About Hungry Hub",
      blog: "Blog",
      ourStroy: "Our Story",
      privacy: "Privacy & Policy",
      meetTheTeam: "Meet the Team",
      hungryPoint: "Hungry Points",
      contactUs: "Contact us",
    },
    th: {
      webTitle:
        "แหล่งรวมบุฟเฟ่ต์อันดับ 1 ในประเทศไทย อิ่มอร่อยกับร้านอาลาคาร์ทชั้นนำแบบบุฟเฟ่ต์ในงบที่คุมได้ จ่ายราคาเน็ท ",
      endWebTitle: "จองและยกเลิกฟรี!",
      followUs: "ติดตามเรา",
      bookARestaurant: "จองร้านอาหาร",
      newRestaurant: "ร้านอาหารใหม่",
      topRestaurant: "ร้านอาหารยอดนิยม",
      groupDinning: "จองโต๊ะกลุ่มใหญ่",
      blogReview: "รีวิวจาก Bloggers",
      support: "ช่วยเหลือ",
      howToBook: "วิธีการจองโต๊ะ",
      faq: "คำถามที่พบบ่อย",
      askAQuestion: "ถามคำถาม",
      shareFeedback: "ข้อเสนอแนะ",
      aboutHungryhub: "เกี่ยวกับ Hungry Hub",
      blog: "บล็อก",
      ourStroy: "เรื่องราวของพวกเรา",
      privacy: "ข้อมูลส่วนบุคคลและข้อกำหนดต่าง ๆ",
      meetTheTeam: "เกี่ยวกับทีมงาน",
      hungryPoint: "เกี่ยวกับ Hungry Points",
      contactUs: "ติดต่อเรา",
    },
  },
});

defineOptions({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: "Footer",
});
</script>
